.alert-enter {
    opacity: 0;
    width: 80%;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    flex-wrap: nowrap;
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    width: 80%;
    flex-wrap: nowrap;
    transition: opacity 300ms, transform 300ms;
  }
  .row-active{
      
    flex-wrap: nowrap;
  }
  .row{
      padding: 20px;
  }